// CREATE CONTEXT PROVIDER FOR APPLICAITON STATE

import { defaultLanguages } from "@constants";
import React, { createContext, useReducer, ReactNode, useContext } from "react";

// Application state

export interface IApplicationState {
  theme: any;
  language: any;
  tenant: number;
  lender_id: number;
  default_lender: any;
  slug: string;
  openChat?:boolean;
  default_currency: number;
  default_currency_symbol: string;
  default_currency_code: string;
  isUserLoggedIn: boolean;
  default_radius: number;
  mileage_unit: string;
  social_media_icons: any;
  collapse_state: boolean;
  dealer_code: string;
  tenant_name: string;
  header_links: [];
  allowed_signin_methods:any;
  footer_links: [];
  email: string;
  company_code: string;
  min_months_stay_at_address:number;
  lender_code: string;
	tenant_id: number;
}

// Initial state

export const initialState: IApplicationState = {
  theme: {},
  language: { placeholders: "" },
  tenant: 0,
  lender_id: 0,
  default_lender: {},
  slug: '',
  openChat:false,
  default_currency: 0,
  default_currency_symbol: '',
  default_currency_code: '',
  isUserLoggedIn: !!localStorage.getItem('access_token'),
  default_radius: 0,
  mileage_unit: '',
  social_media_icons: {},
  collapse_state: false,
  dealer_code: '',
  tenant_name:'',
  allowed_signin_methods:[],
  header_links: [],
  footer_links: [],
  email: '',
  company_code: '',
  lender_code:'',
  min_months_stay_at_address:null,
	tenant_id: null
}

// Action types
export enum ActionType {
	LOAD_CHAT ="LOAD_CHAT",
	LOAD_TENANT_CONFIGURATIONS = "LOAD_TENANT_CONFIGURATIONS",
	LOAD_CURRENCT_CONFIGURATIONS = "LOAD_CURRENCT_CONFIGURATIONS",
	CHANGE_USER_LOGGED_IN_STATE = "CHANGE_USER_LOGGED_IN_STATE",
	UPDATE_HEADER_FOOTER = "UPDATE_HEADER_FOOTER",
	UPDATE_COLLAPSE_STATE = "UPDATE_COLLAPSE_STATE",
	UPDATE_DEALER_CODE = "UPDATE_DEALER_CODE",
	LOAD_DEFAULT_LENDER_CONFIGURATIONS = "LOAD_DEFAULT_LENDER_CONFIGURATIONS",
	UPDATE_TENANT_THEME = "UPDATE_TENANT_THEME",
	PLACEHOLDERS = "PLACEHOLDERS",
}

// Action methods
export const loadTenantConfigurations = (configurations: object) => ({
	type: ActionType.LOAD_TENANT_CONFIGURATIONS,
	payload: configurations,
});

export const loadDefaultLenderConfigurations = (configurations: object) => ({
	type: ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS,
	payload: configurations,
});

export const loadDefaultCurrencyConfigurations = (curreny: object) => ({
	type: ActionType.LOAD_CURRENCT_CONFIGURATIONS,
	payload: curreny,
});

export const openChatBox = (ChatOpen: boolean) => ({
	type: ActionType.LOAD_CHAT,
	payload: ChatOpen,
});
export const changeUserLoggedInState = (isLoggedIn: boolean) => ({
	type: ActionType.CHANGE_USER_LOGGED_IN_STATE,
	payload: isLoggedIn,
});
export const updateHeaderFooter = (icons: {}, headerLinks: [], footerLinks: [], dealorId) => ({
	type: ActionType.UPDATE_HEADER_FOOTER,
	payload: { icons: icons, header_links: headerLinks, footer_links: footerLinks, dealor_id: dealorId },
});

export const updateCollapseState = () => ({
	type: ActionType.UPDATE_COLLAPSE_STATE,
});

export const updateDealerCode = (dealerCode) => ({
	type: ActionType.UPDATE_DEALER_CODE,
	payload: dealerCode,
});

export const updateTenantTheme = (theme) => ({
	type: ActionType.UPDATE_TENANT_THEME,
	payload: theme,
});


export const loadPlaceholder = (configurations: object) => ({
	type: ActionType.PLACEHOLDERS,
	payload: configurations,
});

// Reducer
export const applicationStateReducer = (state: IApplicationState = initialState, action: { type: ActionType; payload: any }) => {
	switch (action.type) {
		case ActionType.LOAD_TENANT_CONFIGURATIONS:
			// Load tenant configurations
			return {
				...state,
				language: defaultLanguages,
				tenant: action?.payload?.id,
				lender_id: action?.payload?.lender_id,
				slug: action?.payload?.slug,
				allowed_signin_methods:action?.payload?.allowed_signin_methods,
				default_currency: action?.payload?.default_currency,
				default_radius: action?.payload?.default_radius,
				mileage_unit: action?.payload?.mileage_unit,
				tenant_name: action?.payload?.name,
				email: action?.payload?.email,
				company_code: action?.payload?.company_code,
				min_months_stay_at_address:action?.payload?.min_months_stay_at_address,
				tenant_id:action?.payload?.tenant_id
			};
		case ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS:
			return {
				...state,
				lender_id: action.payload?.data?.id,
				default_lender: action.payload?.data
			};
		case ActionType.LOAD_CHAT:
			return {
				...state,
				openChat: action.payload,
			};
		case ActionType.LOAD_CURRENCT_CONFIGURATIONS:
			if (action.payload) {
				return {
					...state,
					default_currency_symbol: action.payload.symbol,
					default_currency_code: action.payload.code,
				};
			}
		case ActionType.CHANGE_USER_LOGGED_IN_STATE:
			return {
				...state,
				isUserLoggedIn: action.payload,
			};
		case ActionType.UPDATE_HEADER_FOOTER:{
			return {
				...state,
				social_media_icons: action.payload.icons,
				header_links: action.payload.header_links,
				footer_links: action.payload.footer_links,
				dealer_code: action.payload.dealor_id,
				collapse_state: false,
			};}
		case ActionType.UPDATE_COLLAPSE_STATE:
			return {
				...state,
				collapse_state: !state.collapse_state,
			};

		case ActionType.UPDATE_DEALER_CODE:
			return {
				...state,
				dealer_code: action.payload,
			};

		case ActionType.UPDATE_TENANT_THEME:
			const head = document.head;
			const link = document.createElement("link");
			link.type = "text/css";
			link.rel = "stylesheet";
			link.href = action?.payload?.stylesheet;
			head.appendChild(link);
			return {
				...state,
				theme: action.payload,
			};
			case ActionType.PLACEHOLDERS:
				return {
				  ...state,
				  language: {placeholders:{
					...defaultLanguages?.placeholders,
					...action?.payload?.placeholders
				  }}
				};
		default:
			return state;
	}
};

// Create context
export const AppContext = createContext<{
	state: IApplicationState;
	dispatch: React.Dispatch<any>;
}>({
	state: initialState,
	dispatch: () => null,
});

// Create provider

export const ApplicationContextProvider = ({ children }: { children: ReactNode }) => {
	const [state, dispatch] = useReducer(applicationStateReducer, initialState);

	return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
