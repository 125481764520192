import { Payment } from '@libraries'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@constants'
import { IContractCreditRating, IContractMileage, IContractTerm, IDealerProfile, IStates, IVehicle } from 'interfaces'
import { useGetDealerFeebyState, useGetDealerPreference } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '@app/context-provider'
import { useOrderContext } from 'pages'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType, PaymentType } from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'
import {
  updateContractTerm, updateDealerFee,
  updateOrderFee,
  updateSetupValues
} from 'pages/(order-management)/order/order-context-provider'
import { useGetContractTerms } from 'hooks/dealer-management'

export const PaymentsPage = ({programdata, programLoading, setupDataLoading, }) => {
  const queryClient = useQueryClient();
  const { tenant, vin } = useParams()
  const { mutate: createTags } = useTags();
  const appState = useContext(AppContext);
  const { state: { order: orderState }, dispatch: orderDispatch } = useOrderContext()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const dealer_profile = JSON.parse(localStorage.getItem("dealer_information"))?.dealer
  const [lastSelectedTerm, setLastSelectedTerm] = useState(orderState.contract_term)
  const states: IStates[] = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  const dealerProfile: IDealerProfile = queryClient.getQueryData([
    QueryKeys.DEALER_PROFILE, dealer_profile?.dealer_code]
  )
  const setupData = queryClient.getQueryData(QueryKeys.GET_SETUP_DATA)
  const { data: dealerPreference, isLoading: dealerPreferenceLoading } = useGetDealerPreference(
    dealer_profile?.dealer_code,
    appState?.state?.slug
  );
  const { mutate: dealerFee } = useGetDealerFeebyState(appState?.state?.slug)
  const { isFetching:termLoading, data: terms } = useGetContractTerms(appState?.state?.tenant,tenant)
  const [activeFees, setActiveFees] = useState([]);
  function Termsmap(){
    let defaultTerms
    if(dealerPreference?.default_term_lease_id && orderState.finance_type === PaymentType.Lease)
      {
        defaultTerms =  terms.find((term: IContractTerm) => term?.id === dealerPreference?.default_term_lease_id)?.term
      }else if (dealerPreference?.default_term_finance_id && orderState.finance_type == PaymentType.Finance)
      {
        defaultTerms =  terms.find((term: IContractTerm) => term?.id === dealerPreference?.default_term_finance_id)?.term
      }
      return defaultTerms
  }

  useEffect(()=>{
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.INVENTORY_PAGE_END,
      user_id: user_profile?.user_id,
      dealer_id:+dealer_profile?.dealer_id,
      vehicle_make:orderState?.order_asset?.make,
      vehicle_model:orderState?.order_asset?.model,
      vin:orderState?.order_asset?.vin,
      order_id:orderState?.identifier,
      page_type:PageType.InventoryImpression
      }
      createTags(event)
      const landing_event: Event = {
        session: user_session,
        slug:tenant,
        event_name:Tags.DISPLAY_PAYMENT,
        user_id: user_profile?.user_id,
        dealer_id:+dealer_profile?.dealer_id,
        vehicle_make:orderState?.order_asset?.make,
        vin:orderState?.order_asset?.vin,
        vehicle_model:orderState?.order_asset?.model,
        order_id:orderState?.identifier,
        page_type:PageType.Payments
        }
        createTags(landing_event)
        localStorage.removeItem('reference_dealer');
  },[])

  useEffect(() => {
    if (terms && setupData && dealerPreference && !orderState.contract_term && !orderState.annual_usage) {
      const defaultTerms = Termsmap()
      const defaultMiles = setupData?.['contract-allowed-annum-mileage']?.find((mileage: IContractMileage) => mileage?.id === dealerPreference?.default_mileage_id)
      const defaultCreditRating = setupData?.['credit-ratings']?.find((rating: IContractCreditRating) => rating?.description === 'Excellent')?.description || setupData?.['credit-ratings']?.[0]?.description;
      orderDispatch(updateSetupValues({ defaultTerms, defaultMiles, defaultCreditRating, company_id: appState?.state?.tenant  }))
      setLastSelectedTerm(defaultTerms)
    }
    else {
          if(lastSelectedTerm)
            orderDispatch(updateContractTerm(lastSelectedTerm))
    }
  }, [setupData, dealerPreference, orderState.finance_type,terms])

  useEffect(() => {
    if (programdata && !programdata?.detail && orderState.finance_type !== programdata?.[0]?.finance_type) {
      const defaultTerms = Termsmap()
      const defaultMiles = setupData?.['contract-allowed-annum-mileage']?.find((mileage: IContractMileage) => mileage?.id === dealerPreference?.default_mileage_id)
      const defaultCreditRating = setupData?.['credit-ratings']?.find((rating: IContractCreditRating) => rating?.description === 'Excellent')?.description || setupData?.['credit-ratings']?.[0]?.description;
      orderDispatch(updateSetupValues({ defaultTerms, defaultMiles, defaultCreditRating, company_id: appState?.state?.tenant }))
    }
    else if(programdata?.detail) {
      if(lastSelectedTerm)
        orderDispatch(updateContractTerm(lastSelectedTerm))
    }

  }, [orderState.finance_type, programdata,terms])


  useEffect(() => {
    if (dealerProfile?.dealer_address?.state_id && states && orderState?.order_fees?.length === 0) {
      const stateCode = states?.find((x) => x?.id === dealerProfile?.dealer_address?.state_id)?.code;
      if (stateCode && dealerProfile?.dealer_code) {
        dealerFee({ stateCode, dealerCode: dealerProfile?.dealer_code }, {
          onSuccess(data) {
            const activeFees = data?.filter(fee => fee.is_active);
            setActiveFees(activeFees);
              orderDispatch(updateOrderFee(activeFees));
          }
        });
      }
    }
  }, [dealerProfile, states, orderState?.order_asset, orderState?.order_fees?.length, dealerFee, orderDispatch, updateOrderFee]);

  // Filter fees based on finance_type when it changes
  useEffect(() => {
    if (orderState?.finance_type) {
      const fees = activeFees.length ? activeFees : activeFees;
      const filteredFees = fees
        ?.filter(fee => fee.applicable_finance_type.includes(orderState?.finance_type))
        .map(fee => ({
          ...fee,
          fee_amount: fee?.default_amount,
          fee_handling: fee?.fee_handling,
          dealer_fee_id: fee?.id,
          applied_price: fee?.default_amount,
          is_taxable: fee?.taxable,
          fee_type: fee?.event
        }));

      orderDispatch(updateOrderFee(filteredFees));
    }
        const event: Event = {
          session: user_session,
          slug:tenant,
          event_name:Tags.PAYMENT_CALC,
          user_id: user_profile?.user_id,
          dealer_id: +orderState?.dealer_id,
          vehicle_make: orderState?.order_asset?.make,
          vehicle_model: orderState?.order_asset?.model,
          order_id:orderState?.identifier,
          page_type:PageType.FIPage,
          deal_type: orderState?.finance_type
          }
        createTags(event)
  }, [orderState?.finance_type, activeFees, orderDispatch, updateOrderFee]);

  return (
    <Payment
      isLoading={programLoading}
      setLastSelectedTerm={setLastSelectedTerm}
      setupDataLoading={setupDataLoading || (dealerPreferenceLoading)}
      program={programdata?.detail ? null : programdata?.[0]}
    />
  )
}
