import { Payment } from '@libraries'
import { useQueryClient } from 'react-query'
import { FinanceTypes, QueryKeys } from '@constants'
import { IContractCreditRating, IContractMileage, IContractTerm, IDealerProfile, IStates, IVehicle } from 'interfaces'
import { getVehicleDetailByVin, useGetAllPrograms, useGetDealerFeebyState, useGetDealerPreference, useGetSetupData, useGetVehicleByTrimCode,useGetVehicleByVin,useUpdateLead } from 'hooks'
import { useContext, useEffect, useState } from 'react'
import { AppContext } from '@app/context-provider'
import { ActionType, usePaymentContext } from './payment-context-provider'
import { useOrderContext } from 'pages'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType } from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'
import { programData,updateOrderFee, updateSetupValues } from 'pages/(order-management)/order/order-context-provider'
export const PaymentsPage = () => {
  const queryClient = useQueryClient();
  const { tenant, vin } = useParams()
  const { mutate: createTags } = useTags(tenant);
  const appState = useContext(AppContext);
  const { dispatch } = usePaymentContext()
  const { state: { order: orderState }, dispatch: orderDispatch } = useOrderContext()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const queryParams = `company_id=${appState.state.tenant}&setup_data_indicators=credit-ratings&setup_data_indicators=contract-terms&setup_data_indicators=contract-allowed-annum-mileage`

  const vehicle: IVehicle = queryClient.getQueryData(
    [QueryKeys.VEHICLE, vin]
  );
  const states: IStates[] = queryClient.getQueryData(
    QueryKeys.GET_ALL_STATES
  );
  const dealerProfile: IDealerProfile = queryClient.getQueryData(
    QueryKeys.DEALER_PROFILE
  );


  // Hooks calling
  const { data: setupData, isLoading: setupDataLoading } = useGetSetupData(queryParams)
  const { data: dealerPreference, isLoading: dealerPreferenceLoading } = useGetDealerPreference(vehicle?.dealer_code)
  const { data: assetData, isLoading: assetDataLoading } = useGetVehicleByTrimCode(vehicle?.model_code)
  const { mutate: getAllPrograms, data: programdata, isLoading: programLoading } = useGetAllPrograms()
  const { mutate: dealerFee, data: dealerFeedata, isLoading: dealerFeeLoading } = useGetDealerFeebyState()
  const [activeFees, setActiveFees] = useState([]);


  const payload = {
    dealer_id: vehicle?.dealer_id,
    asset_make_id: assetData?.asset_make_id,
    asset_model_id: assetData?.asset_model_id,
    asset_model_detail_id: assetData?.asset_trim_id,
    credit_rating: orderState.credit_rating,
    make_name: vehicle?.make,
    model_name: vehicle?.model,
    rental_frequency: 'Monthly',
    rental_mode: null,
    annual_usage: orderState.annual_usage,
    terms: orderState?.contract_term,
    retrieve_all_programs: false,
    company_code: appState?.state.company_code,
    model_code: vehicle?.model_code,
    dealer_code: vehicle?.dealer_code,
    model_detail_name: vehicle?.trim_description

  }
  useEffect(()=>{
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.INVENTORY_PAGE_END,
      user_id: user_profile?.user_id,
      dealer_id:+vehicle?.dealer_id,
      vehicle_make:vehicle?.make,
      vehicle_model:vehicle?.model,
      vin:vehicle?.vin,
      order_id:orderState?.identifier,
      page_type:PageType.InventoryImpression
      }
      createTags(event)
      const landing_event: Event = {
        session: user_session,
        slug:tenant,
        event_name:Tags.DISPLAY_PAYMENT,
        user_id: user_profile?.user_id,
        dealer_id:+vehicle?.dealer_id,
        vehicle_make:vehicle?.make,
        vin:vehicle?.vin,
        vehicle_model:vehicle?.model,
        order_id:orderState?.identifier,
        page_type:PageType.Payments
        }
        createTags(landing_event)
  },[])
  useEffect(() => {
    if (setupData && dealerPreference && !orderState.contract_term && !orderState.annual_usage) {
      const defaultTerms = setupData?.['contract-terms']?.find((term: IContractTerm) => term?.id === dealerPreference?.default_term_id)?.term
      const defaultMiles = setupData?.['contract-allowed-annum-mileage']?.find((mileage: IContractMileage) => mileage?.id === dealerPreference?.default_mileage_id)
      const defaultCreditRating = setupData?.['credit-ratings']?.find((rating: IContractCreditRating) => rating?.description === 'Excellent')?.description
      orderDispatch(updateSetupValues({ defaultTerms, defaultMiles, defaultCreditRating, company_id: appState?.state?.tenant  }))
    }
  }, [setupData, dealerPreference, orderState.finance_type])

  useEffect(() => {
    if (programdata && orderState.finance_type !== programdata?.[0]?.finance_type) {
      const defaultTerms = setupData?.['contract-terms']?.find((term: IContractTerm) => term?.id === dealerPreference?.default_term_id)?.term
      const defaultMiles = setupData?.['contract-allowed-annum-mileage']?.find((mileage: IContractMileage) => mileage?.id === dealerPreference?.default_mileage_id)
      const defaultCreditRating = setupData?.['credit-ratings']?.find((rating: IContractCreditRating) => rating?.description === 'Excellent')?.description
      orderDispatch(updateSetupValues({ defaultTerms, defaultMiles, defaultCreditRating, company_id: appState?.state?.tenant }))
    }

  }, [orderState.finance_type, programData])

  useEffect(() => {
    if (vehicle && orderState.contract_term && orderState.annual_usage && orderState.credit_rating) {
      getAllPrograms({
        ...payload,
        finance_type: orderState.finance_type
      }, {
        onSuccess(data) {
          dispatch({
            type: orderState.finance_type === FinanceTypes.LEASE ? ActionType.UPDATE_LEASE_PROGRAM : ActionType.UPDATE_FINANCE_PROGRAM,
            payload: data?.[0]
          })
          orderDispatch(programData(data?.[0]))
        }
      })
      const event_detail: Event = {
        session: user_session,
        slug:tenant,
        event_name:orderState.finance_type,
        user_id:user_profile?.user_id,
        vehicle_model:payload.model_name,
        vin:orderState.vin,
        dealer_id:Number(payload.dealer_id),
        page_type:PageType.Payments,
        data:JSON.stringify(payload),
        deal_type:orderState.finance_type,
        vehicle_make:vehicle?.make
      }
      createTags(event_detail)
    }
  }, [orderState.contract_term, orderState.annual_usage, orderState.credit_rating, orderState.finance_type, vehicle])

  useEffect(() => {
    if (dealerProfile?.dealer_address?.state_id && states && orderState?.order_fees.length === 0) {
      const stateCode = states?.find((x) => x?.id === dealerProfile?.dealer_address?.state_id)?.code;
      if (stateCode && vehicle?.dealer_code) {
        dealerFee({ stateCode, dealerCode: vehicle?.dealer_code }, {
          onSuccess(data) {
            const activeFees = data?.filter(fee => fee.is_active);
            setActiveFees(activeFees);
            orderDispatch(updateOrderFee(activeFees));
          }
        });
      }
    }
  }, [dealerProfile, states, vehicle, orderState?.order_fees.length, dealerFee, orderDispatch, updateOrderFee]);

  // Filter fees based on finance_type when it changes
  useEffect(() => {
    if (orderState?.finance_type) {
      const fees = activeFees.length ? activeFees : activeFees;
      const filteredFees = fees
        ?.filter(fee => fee.applicable_finance_type.includes(orderState?.finance_type))
        .map(fee => ({
          ...fee,
          fee_amount: fee?.default_amount,
          fee_handling: fee?.fee_handling,
          dealer_fee_id: fee?.id,
          applied_price: fee?.default_amount,
          is_taxable: fee?.taxable
        }));

      orderDispatch(updateOrderFee(filteredFees));
    }
  }, [orderState?.finance_type, activeFees, orderDispatch, updateOrderFee]);

  return (
    <Payment
      isLoading={programLoading}
      setupDataLoading={setupDataLoading || dealerPreferenceLoading || assetDataLoading}
    />
  )
}
